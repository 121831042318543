<template>
  <section id="dashboard-analytics">
    <b-col class="content-header-left mb-1" cols="12" md="12">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">Account</h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb style="font-size: 0.9rem">
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="14"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item active v-if="admin" > MASTER </b-breadcrumb-item>
              <b-breadcrumb-item v-else to="/master"> MASTER </b-breadcrumb-item>

              <b-breadcrumb-item active> Account </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-row class="match-height">
      <b-col md="3" cols="6" xl="2" v-for="data in modules" :key="data.id">
        <statistic-card-vertical
          :icon="data.icon"
          :statistic="data.title"
          color="success"
          :route="data.route"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    // kFormatter,
    StatisticCardVertical,

    // InvoiceList,
  },
  data() {
    return {
      data: [
        {
          icon: "WindIcon",
          title: "Ledger",
          route: "/master/accounting/ledger",
        },
        {
          icon: "UsersIcon",
          title: "Account Group",
          route: "/master/accounting/accountgroup",
        },
        {
          icon: "GridIcon",
          title: "Bank Account",
          route: "/master/accounting/bankaccount",
        },
        {
          icon: "FileTextIcon",
          title: "Bank Statement",
          route: "/master/accounting/bankstatement",
        },
        {
          icon: "SidebarIcon",
          title: "Vendor",
          route: "/master/accounting/vendor",
        },
        {
          icon: "TypeIcon",
          title: "Vendor Type",
          route: "/master/accounting/vendortype",
        },
        {
          icon: "OctagonIcon",
          title: "Vendor Category",
          route: "/master/accounting/vendorcategory",
        },
        {
          icon: "UserIcon",
          title: "Broker",
          route: "/master/accounting/broker",
        },
        {
          icon: "FramerIcon",
          title: "Farmer",
          route: "/master/accounting/farmer",
        },
        {
          icon: "TriangleIcon",
          title: "Purchase Buyer",
          route: "/master/accounting/purchasebuyer",
        },
      ],
      userRole:'',
      sidebarData:[],
      modules:[],
      userData: JSON.parse(localStorage.getItem("userData")),
      admin:false,

    };
  },
  mounted() {
    this.userRole = this.userData.role;
    this.sidebarData = JSON.parse(localStorage.getItem("sidebarData"));
    this.admin= (this.userRole == 'admin')
      this.setRoute();
  },
  methods: {
    // kFormatter
    setRoute () {
      this.sidebarData.map((item) => {
        if (item.title == 'Masters') {
          item.children.map((child)=>{
            if(child.title == 'Account'){
              this.modules = child.children
            }
          })
        }
      })
      this.modules.map((item) => {
        this.data.map((inner) => {
          if (inner.title == item.title) {
            item.icon = inner.icon
            item.route = inner.route
          }
        })
      })
    }
  }
};
</script>
